/*
 * @Descripttion: 个人中心(取消)收藏，(取消)点赞, 跳转
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-01 15:35:30
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-12 15:00:26
 */
import myRouter from '@/router'

export function updateFeedInfo (id, type, feedList) {
  const feedInfo = feedList.filter(item => item.id === id)[0]
  if (type === 1) { // 点赞 1-未点赞 2-点赞过
    if (feedInfo.is_like === 1) {
      feedInfo.is_like = 2
      feedInfo.like_count++
    } else if (feedInfo.is_like === 2) {
      feedInfo.is_like = 1
      feedInfo.like_count > 0 && feedInfo.like_count--
    }
  } else { // 收藏 1-未收藏 2-已收藏
    if (feedInfo.is_collection === 1) {
      feedInfo.is_collection = 2
      feedInfo.collect_count += 1
    } else {
      feedInfo.is_collection = 1
      feedInfo.collect_count -= 1
    }
  }
}
export function toUserCenter (id) {
  myRouter.push({ name: 'personalCenter', query: { id } })
}
