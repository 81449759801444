<!--
 * @Descripttion: 点赞的图标&动画
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-08-15 14:02:13
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-17 11:49:42
-->
<template>
    <p
        @click="handleClick"
        :class="['common-icon like-icon', params.is_like === 2 ? 'hover-no-color' : '' ]"
    >
        <svg
            :class="{'like': params.is_like === 1}"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <template v-if="params.is_like === 1">
                <path
                    fill="#999"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.254 6.037c.66.67 1.132 1.63 1.132 2.664 0 .386-.036.788-.105 1.206h3.77c.431 0 .858.093 1.249.274.391.181.739.446 1.017.776a2.906 2.906 0 0 1 .631 2.412l-1.399 7.26a2.911 2.911 0 0 1-1.022 1.706 2.969 2.969 0 0 1-1.875.665H7.278C6.035 23 5 22.005 5 20.743v-7.26c0-.604.244-1.18.673-1.601a2.289 2.289 0 0 1 1.605-.656h.002l2.18.004a1.796 1.796 0 0 0 1.56-1.764V8.36c0-.75.167-1.51.592-2.133.443-.649 1.118-1.08 1.966-1.2m2.676 1.01c-.653-.662-1.62-1.161-2.676-1.01l2.676 1.01zm-1.425 1.404c-.354-.36-.721-.47-.968-.434-.303.043-.477.171-.596.347-.137.2-.245.531-.245 1.006v1.106a3.795 3.795 0 0 1-3.414 3.76l-.048.004-2.28-.004a.29.29 0 0 0-.202.081.247.247 0 0 0-.076.176v7.26c0 .127.109.257.278.257h12.374a.969.969 0 0 0 .612-.216.911.911 0 0 0 .321-.533l1.399-7.26a.887.887 0 0 0-.197-.746.945.945 0 0 0-.326-.248.976.976 0 0 0-.41-.09h-6.354l.369-1.278c.22-.765.32-1.403.32-1.928 0-.433-.209-.907-.557-1.26z"
                />
                <path d="M9 12h2v10H9V12z" fill="#999" />
            </template>

            <template v-else>
                <path
                    d="M21.051 10.907h-5.025c.239-.826.36-1.56.36-2.206 0-1.467-1.363-2.87-2.667-2.684-1.15.164-1.699 1.118-1.699 2.343v1.106c0 1.42-1.104 2.63-2.508 2.764l-2.234-.004c-.339 0-.664.133-.904.368-.24.236-.374.556-.374.89v7.259C6 21.437 6.572 22 7.278 22h12.374c.454 0 .894-.156 1.243-.44.35-.285.588-.681.672-1.12l1.399-7.26a1.888 1.888 0 0 0-.414-1.58 1.945 1.945 0 0 0-.672-.51 1.976 1.976 0 0 0-.829-.183z"
                    stroke="#F33"
                    stroke-width="2"
                    fill="#fff"
                />
                <path fill="#F33" d="M9 12h2v10H9z" />
                <path fill="#FF9533" d="M9 13H7v8h2z" />
            </template>
        </svg>
        <template v-if="type === 3">
          <span>{{  params.like_count === 0 ? '' : count }}</span>
        </template>
        <template v-else>
          <span>{{ (isTextShow && params.like_count === 0) ? text : count }}</span>
        </template>
    </p>

</template>

<script setup>
import { computed, defineProps, defineEmits, reactive, toRefs } from 'vue'
import { dialogMsg, myToast } from '@/utils/dialogMsg.js'
import { feedsFeedlike } from '@/apis/blogs.js'
import mySocket from '@/mixin/socket.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  type: { // 1-微博,2-论坛,3-评论,4-日志
    type: [String, Number],
    default: '1'
  },
  isTextShow: { // 点赞文案是否显示
    type: Boolean,
    default: true
  },
  isCountShow: { // 点赞数量是否显示
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['handleClick'])

const state = reactive({
  params: computed(() => props.data),
  type: computed(() => Number(props.type)), // 类型
  text: computed(() => props.isTextShow ? '点赞' : ''),
  count: computed(() => props.isCountShow ? state.params.like_count : props.isTextShow ? '点赞' : '')
})

const { params, type, text, count } = toRefs(state)

const { sendToSocket } = mySocket()

const handleClick = () => {
  // 点赞，接口的type和ws的obj_type是一致的
  feedsFeedlike({
    id: state.params.id,
    type: state.type,
    target_user: state.params.user_id
  })
    .then((res) => {
      if (res.code === 0) {
        if (res.data.type === 1) { // 点赞成功, 发送websocket通知
          const socketList = []
          socketList.push(state.params.user_id)
          sendToSocket(socketList, { id: state.params.id, obj_type: state.type, type: 2 })
        }
        emit('handleClick', state.params)
      } else if (res.code === 30007) {
        myToast({ type: 'warning', message: '内容已被删除' })
      } else {
        dialogMsg('error', res.msg)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

</script>

<style lang="less" scoped>
@import '../../assets/less/icon.less';

@keyframes likeAnimation {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.like-icon {
  svg:hover {
      path,
      rect {
        fill: @active-text-color;
        stroke: transparent;
      }
    }
  &.hover-no-color svg {
    &:hover {
      background: transparent;
      path,
      rect {
        fill:transparent;
        stroke: #f33;
      }
    }
  }
}

.hover-no-color-like {
    animation: likeAnimation .3s linear;
    -webkit-animation: likeAnimation .3s linear;
    -moz-animation: likeAnimation .3s linear;
    -o-animation: likeAnimation .3s linear;

}

</style>
