<template>
  <p class="common-icon comment-icon" @click="handleClick(item)">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" d="M14 5c-4.418 0-8 3.504-8 7.825 0 4.229 3.427 7.674 7.701 7.826l.036.047.884 2.302c1.612-1.005 3.2-2.991 4.573-4.23C20.914 17.335 22 15.209 22 12.827 22 8.503 18.418 5 14 5z" stroke="#999" stroke-width="2"/>
    </svg>
    <template v-if="count !== -1">
      <span >{{count === 0 ? '评论' : count}}</span>
    </template>
  </p>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'

const props = defineProps({
  count: {
    type: [String, Number], // 数量
    default: -1
  }
})
const emit = defineEmits(['handleClick'])
const count = computed(() => Number(props.count))
const handleClick = () => {
  emit('handleClick')
}
</script>

<style scoped lang="less">
@import '../../assets/less/icon.less';
</style>
